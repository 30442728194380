<template>
    <div class="Sms1">
        <div class="form-search-box flex-row-start flex-wrap mt10">
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">提现单号:</div>
                <el-input size="mini" style="width:200px" v-model="search.order_no" placeholder="订单号" />
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">打款状态:</div>
                <el-select size="mini" style="width:200px" v-model="search.status" filterable placeholder="请选择状态">
                    <el-option v-for="(item,index) in statusList" :key="index" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">提交时间:</div>
                <el-date-picker style="width:260px" clearable @change="GetDate" v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" />
            </div>
            <div class="flex-row-start">
                <a-button @click="getLists(1)" type="primary">查 询</a-button>
                <a-button @click="onReset" class="ml10">重 置</a-button>
            </div>
        </div>
        <div class="table-container mt20">
            <div class="wxb-table-white">
                <el-table v-loading="loading" ref="multipleTable" :data="tableData" fit highlight-current-row>
                    <el-table-column align="center" label="ID" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.id? scope.row.id:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="提现单号" minWidth="170">
                        <template slot-scope="scope">
                            {{ scope.row.order_no? scope.row.order_no:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="提现门店" minWidth="150">
                        <template slot-scope="scope">
                            {{ scope.row.shop_info&&scope.row.shop_info.abbreviation? scope.row.shop_info.shop_number+'-'+scope.row.shop_info.abbreviation:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="账户类型" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.type == 1? '微信':'支付宝'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="打款账户" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.account? scope.row.account:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="提现金额" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.money? scope.row.money:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="提交时间" minWidth="160">
                        <template slot-scope="scope">
                            {{ scope.row.create_time? scope.row.create_time:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="打款状态" minWidth="100">
                        <template slot-scope="scope">
                            <span style="color:#409EFF" v-if="scope.row.status == 1">待打款</span>
                            <span style="color:#67C23A" v-if="scope.row.status == 2">已打款</span>
                            <span style="color:#F56C6C" v-if="scope.row.status == 3">已驳回</span>
                            <span style="color:#F56C6C" v-if="scope.row.status == 4">打款失败</span>
                            <span style="color:#F56C6C" v-if="scope.row.status == 5">已撤回</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="打款接口信息" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.pay_result? scope.row.pay_result:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="打款时间" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.pay_time? scope.row.pay_time:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" minWidth="90">
                        <template slot-scope="scope" v-if="scope.row.status == 1 ||scope.row.status == 4">
                            <el-button style="color:#67C23A" @click="saveSigna(2,scope.row.id)" type="text">打款</el-button>
                            <el-button style="color:#F56C6C" @click="saveSigna(3,scope.row.id)" type="text">退回</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination v-show="pagination.total>0" style="margin-top: 15px;" :total="pagination.total" :page.sync="pagination.current" :limit.sync="pagination.pageSize" @pagination="getLists" />
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            tableData: [],
            time: [],
            loading: false,
            statusList: [
                { title: '全部', id: '' },
                { title: '待打款', id: '1' },
                { title: '已打款', id: '2' },
                { title: '已驳回', id: '3' },
                { title: '打款失败', id: '4' },
                { title: '已撤回', id: '5' },
            ],
            search: {
                order_no: '', status: '', start_time: '', end_time: '',
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
        }
    },
    created() {
        this.getLists(1)
    },
    methods: {
        getLists(res) {
            if (res) {
                this.pagination.current = res
            }
            if (this.loading == true) return;
            this.loading = true;
            this.$http
                .api("api/store/listWithdrawlog", {
                    order_no: this.search.order_no,
                    status: this.search.status,
                    start_time: this.search.start_time,
                    end_time: this.search.end_time,
                    limit: this.pagination.pageSize,
                    page: this.pagination.current,
                    store_id: 1
                })
                .then((res) => {
                    this.tableData = res.data.list.data;
                    this.pagination.total = res.data.list.total;
                    this.loading = false;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        onReset() {
            this.search = {
                order_no: '', status: '', start_time: '', end_time: '',
            }
            this.getLists(1)
        },
        saveSigna(status, id) {
            this.$alert('确认操作?', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$http.api('api/store/saveWithdrawStatus', { id: id, status: status }).then(res => {
                        this.getLists(1)
                    }).catch(res => {
                    })
                }
            });
        },
        GetDate() {
            if (this.time) {
                this.search.start_time = this.time[0]
                this.search.end_time = this.time[1]
            } else {
                this.search.start_time = ''
                this.search.end_time = ''
            }
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-range-separator {
    width: auto !important;
}
.Sms1 {
    .image_bos {
        .image_list {
            height: 70px;
            width: 70px;
            border-radius: 5px;
            overflow: hidden;
            display: inline-block;
            margin-left: 10px;
            position: relative;
            .img {
                height: 70px;
                width: 70px;
            }
            .demo_cover {
                position: absolute;
                top: 0;
                left: 0;
                height: 0px;
                width: 0px;
                background: rgba(0, 0, 0, 0.6);
                overflow: hidden;
                cursor: pointer;
            }
        }
        .image_list:hover {
            .demo_cover {
                height: 70px;
                width: 70px;
            }
        }
    }
}

.file_url {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    cursor: pointer;
}
</style>